.filters-container {
    display: flex;
    justify-content: space-evenly;
    gap: 5px;
    flex-wrap: 'wrap';
}

.filters-container .filter-item{
    flex-grow: 1;
    width: 20%;
}