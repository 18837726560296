/* @import url("antd/dist/reset.css"); */

@font-face {
  font-family: "Barlow";
  src: url("assets/Barlow-Regular.ttf") format("truetype");
}



.login-container {
  height: 100vh;
  background: #eeeeee;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-form {
  padding: 10px 50px;
  background: white;
  width: 500px;
  box-shadow: 0 0 5px silver;
  border-radius: 6px;
}

.text-sm {
  font-size: 12px;
}

.text-light {
  color: grey;
}

.App-logo {
  height: 70px;
  pointer-events: none;
}

.ant-layout {
  min-height: 100vh;
}

.ant-table-wrapper .ant-table-pagination.ant-pagination {
  padding-top: 12px;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #a7a7a754;
  border-radius: 9px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: silver;
  border-radius: 10px;
}

.ant-modal-confirm .ant-modal-confirm-btns .ant-btn+.ant-btn {
  background: #f5be4a;
}